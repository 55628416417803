import React, { Component } from "react"
import { graphql } from "gatsby"
import Footer from "../components/Footer"

import App from "../components/App"
import CTA from "../components/CTA"
import SEO from "../components/seo"
import Hero from "../components/Hero"

import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import CardDeck from "react-bootstrap/CardDeck"

class Resources extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    const resources = this.props.data.allContentfulResource.edges
    this.setState({
      data: resources
    })
  }

  render() {
    const { cta, hero } = this.props.data.allContentfulResourcesMain.edges[0].node
    const navbarData = this.props.data.allContentfulNavbar.edges[0].node
    const footerData = this.props.data.allContentfulFooter.edges[0].node

    const postImageStyle = url => {
      return {
        backgroundImage: `url(${url})`,
      }
    }

    const cardCatBGStyle = color => {
      return { backgroundColor: color }
    }

    const cardCatLabelStyle = color => {
      return { color: color }
    }


    return (
      <App navbar={navbarData}>
        <SEO title="Resources" />
        <Hero navbar={navbarData} hero={hero} />
        <div className="content blog-content">
          <Container className="content-section pt-lg pb-lg">
            <CardDeck className="post-cards">
              {this.state.data.map((edge, key) => (
                <Card key={key} className="post-card">
                  <a
                    className="card-image"
										href={edge.node.downloadableFile.file.url}
										target="_blank"
                    style={postImageStyle(edge.node.image.file.url)}
                  ></a>
                  <Card.Body>
                    {edge.node.resourceCategory && 
                    <span className="card-category">
                      <span
                        className="card-category-bg"
                        style={cardCatBGStyle(edge.node.resourceCategory.color)}
                      >sss</span>
                      <span
                        className="card-category-label"
                        style={cardCatLabelStyle(edge.node.resourceCategory.color)}
                      >
                        {edge.node.resourceCategory.title}
                      </span>
                    </span>}
                    <h4 className="card-title">
                      <a href={edge.node.downloadableFile.file.url} target="_blank">{edge.node.title}</a>
                    </h4>
                    <p className="card-text">{edge.node.excerpt}</p>
                    <a href={edge.node.downloadableFile.file.url} className="card-readmore" target="_blank">
                      Learn more &rarr;
                    </a>
                  </Card.Body>
                </Card>
              ))}
            </CardDeck>
          </Container>
        </div>
        <CTA cta={cta} />
        <Footer footer={footerData} />
      </App>
    )
  }
}

export default Resources

export const resourcesQuery = graphql`
  query resourcesQuery {
    allContentfulResource(sort: {order: DESC, fields: createdAt}) {
      edges {
        node {
          image {
            file {
              url
            }
          }
					downloadableFile {
            file {
              url
            }
          }
          title
          slug
          excerpt
          resourceCategory {
            title
            slug
            color
          }
        }
      }
    }
    allContentfulResourcesMain {
      edges {
        node {
          description
          title
          cta {
            title
            buttonCTA {
              link
              label
            }
          }
          hero {
            title
            label
            backgroundImage {
              file {
                url
              }
            }
            backgroundVideo {
              backgroundVideo
            }
            content {
              json
            }
          }
        }
      }
    }
    allContentfulNavbar {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
          colophon
        }
      }
    }
  }
`
